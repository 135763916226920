/***************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/
@use "globals" as *;

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: $serif;
  color: $darkBlue;
  background: $grey--background;
  word-break: break-word;
  overflow: hidden;
  // growing and shrinking the interface was proving quite expensive for very little benefit, going
  // to stick to one size and see how we feel, leaving the clamp commented out so that we can easily
  // revert and remember what it used to do
  font-size: 0.875em;
  line-height: 1.25em;
  // font-size: clamp(.75em, 1vw, 1.125em);
  margin: 0;
}

input[type="text"],
input[type="password"],
input[type="time"],
input[type="email"],
input[type="number"],
input[type="file"],
input[type="radio"],
input[type="checkbox"],
input[type="search"],
select,
textarea {
  line-height: 1.5em;
}

#react_container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: $sans;
  width: 100vw;
  height: 100vh;
  padding: 7em 2em 2em 2em;
  nav {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    height: 5em;
    width: 100vw;
    background: #fff;
    border-bottom: 1px solid $grey--divider;
    box-shadow: $boxShadow--light;
    padding: 1.25em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    .innerWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 1400px;
      .websiteLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .inlineSVG {
          height: 2.5em;
          &.logoText {
            margin-left: 0.5em;
            height: 2.25em;
            width: 13em;
          }
        }
        span {
          color: $darkBlue;
          margin-left: 0.5em;
        }
        &:hover {
          text-decoration: none;
        }
      }
      .websiteLink--pageLink {
        color: $darkBlue;
        display: block;
        text-decoration: none;
        padding: 1em 2em;
        border-radius: $borderRadius--input;
        font-weight: 600;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: $primary--10;
          color: $primary;
        }
      }
      .tryNowLink {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: $primary;
        background: linear-gradient(90deg, $primary 0%, $darkBlue 80%);
        padding: 0.5em 0.75em 0.5em 2em;
        border-radius: $borderRadius--full;
        height: 3em;
        color: #fff;
        transition: all 0.3s ease-in-out;
        box-shadow: $boxShadow--button;
        span.text {
          font-weight: 500;
          font-size: 1.25em;
        }
        .inlineSVG {
          margin-left: 1em;
          width: 2em;
          height: 2em;
          path {
            fill: #fff;
          }
        }
        &:hover {
          text-decoration: none;
          background: linear-gradient(90deg, $darkBlue 0%, $darkBlue 100%);

        }
      }
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 1em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

h1 {
  font-size: 1.25em;
  margin: 0;
}

h2 {
  font-size: 1.25em;
  margin: 0;
}

h3 {
  font-size: 1.125em;
  padding: 0 0 0.5em 0;
  margin: 0;
}

p {
  margin: 0;
  margin-bottom: 1em;
  line-height: 1.5em;
  &.title {
    margin-bottom: 0.2em;
    font-weight: bold;
    font-size: .875em;
    font-family: $sans
  }
  &.section {
    margin-bottom: 0.2em;
    font-weight: bold;
    font-style: italic;
    font-size: .75em;
  }
  &.technical {
    white-space: pre-wrap;
    font-family: $fixed;
    margin-bottom: 0;
    padding: 0 0 0 1em;
  }
}

a {
  color: $primaryBlue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: $primaryBlue;
  }
}

pre {
  background: $grey--background;
  padding: 1em;
  border-radius: $borderRadius;
  border: none;
  white-space: pre-wrap;
}